<template>
  <div>oidcCallback</div>
</template>

<script lang="ts">
import { authService } from '@/services/auth.service'
import { defaultLanguage } from '@/services/language'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'OidcCallback',
  created() {
    console.log('oidcCallback created   :: ')
    authService
      .signinCallback(location.href)
      .then((user) => {
        console.log('oidcCallback_success then  :: ', user)
        this.$router.push({ name: 'home', params: { lang: defaultLanguage } }).catch((e) => {
          //this.$router.push({ name: 'catchall'}).catch((e) => {
          /* duplicated route */
        })
      })
      .catch((err) => {
        console.error('oidcCallback_error catch route to home  :: ', err)
        // Handle errors any way you want
        this.$router.push({ name: 'home', params: { lang: defaultLanguage } }).catch((e) => {
          //this.$router.push({ name: 'catchall'}).catch((e) => {
          /* duplicated route */
        })
      })
  }
})
</script>
